
body {
    background-color: white;
}
.canvas-btn {
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border-style: solid;
    padding: 5px 5px;
    border-color: gray;
    border-width: thin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.btn-width {
    min-width: 50px;
}

.mouse-state {
    cursor: none;
}